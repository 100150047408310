import React, { useEffect, useState } from "react";
// import { makeStyles } from '@material-ui/core/styles';
import "./styleForm.css";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  Button,
  Box,
} from "@mui/material";
import BackupIcon from "@mui/icons-material/Backup";
import { Document, Page } from "react-pdf";
import { alpha, styled } from "@mui/material/styles";
import { FaCrown } from "react-icons/fa";
import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MdCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { FaPencilAlt } from "react-icons/fa";
import Modal from "react-modal";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import {
  SubmitBioDataService,
  fetchBiodataTemplates,
  getAllDropdown,
  getAllLangauge
} from "../../../services/ApiService";
import { clearForm, getForm, getUserId1, setForm, setIsPayment, setPdf, setResume, setTemp } from "../../../utils/localStorage";
import { BiSolidSelectMultiple } from "react-icons/bi";
import axios from "axios";
import { BsFillNutFill } from "react-icons/bs";
import { Navigate } from "react-router-dom";
import { FaLessThanEqual, FaSleigh } from "react-icons/fa";
import { FiShield } from "react-icons/fi";
import ImagePath from "../../../assets/images";
import { storeResumeId, storeSubmit, storeTemplate } from "../../../Redux/action";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
// import { WebView } from "react-native-webview";
import { useRef } from "react";
import ReactCrop from "react-image-crop";
import fontStyle from "../../../assets/fontStyle";
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";
import { generateDownload } from "../../../utils/cropImage";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "grey",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "red",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "lightgrey",
      borderRadius: 7,
      borderWidth: "1px",
      backgroundColor: "#ffd2eb33",
    },
    "&:hover fieldset": {
      borderColor: "#f35491",
    },  
    "&.Mui-focused fieldset": {
      borderColor: "#f35491",
    },
  },
});

const CssSelectField = styled(Select)({
  "& label.Mui-focused": {
    color: "grey",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "red",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "lightgrey",
      borderRadius: 7,
      borderWidth: "1px",
      backgroundColor: "#ffd2eb33",
    },
    "&:hover fieldset": {
      borderColor: "#f35491",
    },  
    "&.Mui-focused fieldset": {
      borderColor: "#f35491",
    },
  },
});

const CssTextField1 = styled(TextField)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "red",
    backgroundColor: "red",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#ffffff",
      borderRadius: 1,
      borderWidth: 2,
    },
    "&:hover fieldset": {
      borderColor: "#0000ff",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#0000ff",
    },
  },
});

const genderr = ["Male", "Female"];
const time = ["AM", "PM"];

const dateField = [];
for (let i = 1; i <= 31; i++) {
  const day = i < 10 ? `0${i}` : `${i}`;
  dateField.push(
    <MenuItem key={day} value={day}>
      {day}
    </MenuItem>
  );
}

const monthField = [];
for (let i = 1; i <= 12; i++) {
  const day = i < 10 ? `0${i}` : `${i}`;
  monthField.push(
    <MenuItem key={day} value={day}>
      {day}
    </MenuItem>
  );
}

const currentYear = new Date().getFullYear(); // Get current year
const minYear = currentYear - 64;
const maxYear = currentYear - 18;
const yearField = [];
for (let i = minYear; i <= maxYear; i++) {
  yearField.push(
    <MenuItem key={i.toString()} value={i.toString()}>
      {i.toString()}
    </MenuItem>
  );
}

const hourField = [];
for (let i = 1; i <= 12; i++) {
  hourField.push(
    <MenuItem key={i.toString()} value={i.toString()}>
      {i}
    </MenuItem>
  );
}

const minuteField = [];
for (let i = 0; i <= 59; i++) {
  minuteField.push(
    <MenuItem key={i.toString()} value={i.toString()}>
      {i}
    </MenuItem>
  );
}

const numberField = [];
for (let i = 0; i <= 10; i++) {
  numberField.push(
    <MenuItem key={i} value={i}>
      "{i}"
    </MenuItem>
  );
}

const number = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
const number1 = ["0", "1", "2"];

const InitialFormData = {
  user_id: "",
  name: "",
  gander: "",
  image: null,
  date_of_birth: "",
  time_of_birth: "",
  place_of_birth: "",
  nakshatra_id: "",
  height_id: "",
  religion_id: "",
  mother_tongue_id: "",
  cast: "",
  sub_cast: "",
  gotra: "",
  manglik_id: "",
  education_id: "",
  college_name: "",
  employed_in_id: "",
  organization_name: "",
  annual_income_id: "",
  father_name: "",
  father_employed_in_id: "",
  mother_name: "",
  mother_employed_in_id: "",
  total_brother: "",
  contact_number: "",
  email_id: "",
  marital_status_id: "",
  total_sister: "",
  married_brother: "",
  married_sister: "",
  address: "",
};

const Form = () => {
  //   const classes = useStyles();
  const [activeSection, setActiveSection] = useState("personal");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const TemplatePdf = useSelector((store) => store.reducer.template);
  const { t, i18n } = useTranslation();

  
  //handle form upload
  const [formData, setFormData] = useState({
    user_id: getUserId1(),
    created_for_id: "",
    name:  "",
    gander: "",
    image: null,
    date: "",
    month: "",
    year: "",
    hour: "",
    minute: "",
    am: "",
    date_of_birth: "",
    time_of_birth: "",
    place_of_birth: "",
    rashi_id: "",
    nakshatra_id: "",
    height_id: "",
    religion_id: "",
    mother_tongue_id: "",
    cast: "",
    sub_cast: "",
    gotra: "",
    manglik_id: "",
    education_id: "",
    college_name: "",
    employed_in_id: "",
    organization_name: "",
    annual_income_id: "",
    father_name: "",
    father_employed_in_id: "",
    mother_name: "",
    mother_employed_in_id: "",
    total_brother: "",
    contact_number: "",
    email_id: "",
    marital_status_id: "",
    total_sister: "",
    married_brother: "",
    married_sister: "",
    address: "",
    templates_name: "myPDF",
    templates_id: "",
    addressLine2: "",
    title:"",
    sub_title:"",
    title_image:null
  });
  const [submitted, setSubmitted] = useState(false);

  const [dateBirthE, setDateBirthE] = useState(false);
  const [dateE, setDateE] = useState(false);
  const [monthE, setMonthE] = useState(false);
  const [yearE, setYearE] = useState(false);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setDateE(false);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

const  [selectedByLang  ,setSelectedByLang] = useState(false)
  const handleLanguage = (event) =>{
    setSelectedByLang(false)
    const { name, value } = event.target;
    setSelectedLanguage(value)
    
  }

  const handleBeforeUnload = (event) => {
    clearForm();
  };

  useEffect(() => {
    // Add event listener for beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const [template, setTemplate] = useState("");
  const [templateE, setTemplateE] = useState(false);
  const handleTemplateChange = (imageId, imageName) => {
    setTemplate(imageId);
    const idAsString = String(imageId);
    const { value } = imageId;
    console.log("imageId " + imageId);
    setTemplateE(false);
    setFormData((prevData) => ({
      ...prevData,
      templates_name: imageName,
      templates_id: idAsString,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const formSubmit = await getForm();
        const [day, month, year] = formSubmit.date_of_birth.split("-")
        const [hour, minAm] = formSubmit.time_of_birth.split(":")
        console.log(minAm)
        const [min, am] = minAm.split(" ");
        console.log("min",min);
        console.log(formSubmit.name);
        // Update the formData state with fetched data
        setFormData((prevFormData) => ({
          ...prevFormData,
          name: formSubmit.name,
          place_of_birth: formSubmit.place_of_birth,
          cast: formSubmit.cast,
          sub_cast: formSubmit.sub_cast,
          gotra: formSubmit.gotra,
          college_name: formSubmit.college_name,
          father_name: formSubmit.father_name,
          mother_name: formSubmit.mother_name,
          contact_number: formSubmit.contact_number,
          email_id: formSubmit.email_id,
          addressLine1: formSubmit.addressLine1,
          addressLine2: formSubmit.addressLine2,
          organization_name: formSubmit.organization_name,
          date: day,
          month: month,
          year: year,
          hour: hour,
          minute: min,
          am: am,
          gander: formSubmit.gander,
          rashi_id: formSubmit.rashi_id,
          nakshatra_id: formSubmit.nakshatra_id,
          height_id: formSubmit.height_id,
          marital_status_id: formSubmit.marital_status_id,
          religion_id: formSubmit.religion_id,
          mother_tongue_id: formSubmit.mother_tongue_id,
          manglik_id: formSubmit.manglik_id,
          education_id: formSubmit.education_id,
          employed_in_id: formSubmit.employed_in_id,
          annual_income_id: formSubmit.annual_income_id,
          created_for_id: formSubmit.created_for_id,
          father_employed_in_id: formSubmit.father_employed_in_id,
          mother_employed_in_id: formSubmit.mother_employed_in_id,
          total_brother: formSubmit.total_brother,
          total_sister: formSubmit.total_sister,
          married_brother: formSubmit.married_brother,
          married_sister: formSubmit.married_sister,
          title:formSubmit.title,
          sub_title:formSubmit.sub_title,
          title_image:formSubmit.title_image,
          // templates_id: formSubmit.templates_id
          // Add other fields here if necessary
        }));
        setTemplate(formSubmit.templates_id)
        console.log(template);
        
      } catch (error) {
        console.error('Error fetching form data:', error);
      }
    };

    fetchData();

    
  }, []);

  useEffect(()=> {
    window.scrollTo(0, 0);
  },[])

  const [selectedLanguage , setSelectedLanguage] = useState('en')
  const changeLanguage = (language) => {
    i18n.changeLanguage(language)
  };

  useEffect(()=>{
    changeLanguage(selectedLanguage)
  },[selectedLanguage])

  const handleChangeM = (event) => {

    const { name, value } = event.target;
    setMonthE(false);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleChangeY = (event) => {
    const { name, value } = event.target;
    setYearE(false);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [timeBirthE, setTimeBirthE] = useState(false);
  const [hourE, setHourE] = useState(false);
  const [minE, setMinE] = useState(false);
  const [secE, setSecE] = useState(false);
  const [aME, setAME] = useState(false);

  const handleChange2 = (event) => {
    const { name, value } = event.target;
    setHourE(false);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleChange2M = (event) => {
    const { name, value } = event.target;
    setMinE(false);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChange2S = (event) => {
    const { name, value } = event.target;
    setSecE(false);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleChange2A = (event) => {
    const { name, value } = event.target;
    setAME(false);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [button, setButton] = useState(false);
  const handleButton = () => {
    setButton(true);
  };


  //   const dispatch = useDispatch();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const date_of_birth = `${formData.date}-${formData.month}-${formData.year}`;
    const time_of_birth = `${formData.hour}:${formData.minute} ${formData.am}`;

    const addressLine1 = formData.addressLine1;
    const addressLine2 = formData.addressLine2;

    const address =
      (addressLine2 !== undefined || addressLine2 !== "")
        ? `${addressLine1}, ${addressLine2}`
        : addressLine1;

    const updatedFormData = {
      ...formData,
      date_of_birth,
      time_of_birth,
      address,
    };

    try {
      const response = await SubmitBioDataService(updatedFormData);
      // Check if the response has a 'status' property
      if (response.status === "success") {
        console.log("form submitted: ", response);
        setPdf(response.pdfHTML);
        setResume(response.id);
        setTemp(updatedFormData.templates_id)
        setIsPayment(response.is_payment);
        dispatch(storeResumeId(response.id));
        dispatch(storeTemplate(response.pdfHTML));
        dispatch(storeSubmit(true));
        setError(null);
        setSuccess(true);
        navigate("/preview");
      } else if (response.status === "error" || response.status === "fail") {
        // Check if the response has an 'error' property
        console.log("login failed: ", response);
        console.log(response.message);
        const loginerror = response.message;
        setError(loginerror);
      }
    } catch (error) {
      console.log("error in add data ", error);
    }

    // setSubmitted(true);
  };

  const [annualIncome, setAnnualIncome] = useState([]);
  const [educationOp, setEducationOp] = useState([]);
  const [employeeOp, setEmployeeOp] = useState([]);
  const [heightOp, setHeightOp] = useState([]);
  const [manglikOp, setManglikOp] = useState([]);
  const [maritalOp, setMaritalOp] = useState([]);
  const [tongueOp, setTongueOp] = useState([]);
  const [nakshatraOp, setNakshatraOp] = useState([]);
  const [religionOp, setReligionOp] = useState([]);
  const [createdforOp, setCreatedforOp] = useState([]);
  const [rashiOp, setRashiOp] = useState([]);
  const [tempOp, setTempOp] = useState([]);
  const [languages, setLanguages] = useState([])

  const getLangauge = (lang)=>{
    if(lang == 'hi'){
      return 'hindi'
    }
    else if(lang == 'gu'){
      return 'gujarati'
    }
    else if(lang == 'en'){
      return 'english'
    }
  }
  const fetchData = async () => {
    try {
        const lang = getLangauge(selectedLanguage)
        const data = await getAllLangauge(lang||'english');
        console.log("data" , data)
      // console.log(data.annual_income);
      const annualIncomeData = data.data.annual_income;
      const educationData = data.data.education;
      const employeeData = data.data.employed_in;
      const heightData = data.data.height;
      const manglikData = data.data.manglik;
      const maritalData = data.data.marital_status;
      const tongueData = data.data.mother_tongue;
      const nakshatraData = data.data.nakshatra;
      const religionData = data.data.religion;
      const createdforData = data.data.created_for;
      const rashiData = data.data.rashi;
     

      setAnnualIncome(annualIncomeData);
      setEducationOp(educationData);
      setEmployeeOp(employeeData);
      setHeightOp(heightData);
      setManglikOp(manglikData);
      setMaritalOp(maritalData);
      setTongueOp(tongueData);
      setNakshatraOp(nakshatraData);
      setReligionOp(religionData);
      setCreatedforOp(createdforData);
      setRashiOp(rashiData);
     
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [selectedLanguage]);

  useEffect(() => {
    const fetchTemp = async () => {
      try {
        const temp = await fetchBiodataTemplates();
        setTempOp(temp);
        console.log(tempOp);
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };

    fetchTemp();
  }, []);

  useEffect(()=>{
    const languageData = [
      {key : "en" , value : 'English'},
      {key :'hi' , value:'Hindi'},
      {key : 'gu' , value : 'Gujarati'} 
    ]
    setLanguages(languageData)
    fetchData()
  } , [])

  const handleDownload = () => {
    // Create an anchor element
    const anchor = document.createElement("a");
    // Set the href attribute to the URL of the PDF
    anchor.href = TemplatePdf;
    // Set the download attribute to specify the filename when downloading
    anchor.download = { TemplatePdf };
    // Trigger a click event on the anchor element to initiate the download
    anchor.click();
  };

  //to handle input dropdown value
  const [error, setError] = useState(false);
  const [genderE, setGenderE] = useState(false);
  const removeScroll = ()=>{
    setTimeout(() => {
      document.body.classList.remove("no-scroll");
    }, 0);
  }
  const handleGenderChange = (event) => {
    const selectedIndex = event.target.value;
    const selectedValue = event.target.value;
    setGenderE(false);
    setFormData((prevData) => ({
      ...prevData,
      gander: selectedValue,
    }));
  };

  const [selectedBy, setSelectedBy] = useState("");
  const [selectedByE, setSelectedByE] = useState(false);
  const handleByChange = (event) => {
    setSelectedBy(event.target.value);
    setSelectedByE(false);
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      created_for_id: value,
    }));
  };
  
  const [name, setname] = useState("");
  const [nameE, setNameE] = useState(false);
  const handleNameChange = (event) => {
    setname(event.target.value);
    const { value } = event.target;
    setNameE(false);
    setFormData((prevData) => ({
      ...prevData,
      name: value,
    }));
  };

  const [birthPlace, setBirthPlace] = useState("");
  const [birthE, setBirthE] = useState(false);
  const handlePlaceChange = (event) => {
    setBirthPlace(event.target.value);
    const { value } = event.target;
    setBirthE(false);
    setFormData((prevData) => ({
      ...prevData,
      place_of_birth: value,
    }));
  };
  const [title, setTitle] = useState("");
  const handleTitleChange = (event) => {
    setBirthPlace(event.target.value);
    const { value } = event.target;
    setTitle(false);
    setFormData((prevData) => ({
      ...prevData,
      title: value,
    }));
  };
  const [sub_title, setSubTitle] = useState("");
  const handleSubTitleChange = (event) => {
    setSubTitle(event.target.value);
    const { value } = event.target;
    setBirthE(false);
    setFormData((prevData) => ({
      ...prevData,
      sub_title: value,
    }));
  };

  const [selectedTime, setSelectedTime] = useState("");
  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const [rashi, setRashi] = useState("");
  const [rashiE, setRashiE] = useState(false);
  const handleRashiChange = (event) => {
    setRashi(event.target.value);
    const { value } = event.target;
    setRashiE(false);
    setFormData((prevData) => ({
      ...prevData,
      rashi_id: value,
    }));
  };

  const [nakshatra, setNakshatra] = useState("");
  const [nakshatraE, setNakshatraE] = useState(false);
  const handleNakChange = (event) => {
    setNakshatra(event.target.value);
    const { value } = event.target;
    setNakshatraE(false);
    setFormData((prevData) => ({
      ...prevData,
      nakshatra_id: value,
    }));
  };

  const [height, setHeight] = useState(" ");
  const [heightE, setHeightE] = useState(false);
  const handleHChange = (event) => {
    setHeight(event.target.value);
    const { value } = event.target;
    setHeightE(false);
    setFormData((prevData) => ({
      ...prevData,
      height_id: value,
    }));
  };

  const [mStatus, setMStatus] = useState("");
  const [mStatusE, setMStatusE] = useState(false);
  const handleMstatusChange = (event) => {
    setMStatus(event.target.value);
    const { value } = event.target;
    setMStatusE(false);
    setFormData((prevData) => ({
      ...prevData,
      marital_status_id: value,
    }));
  };

  const [religion, setReligion] = useState("");
  const [religionE, setReligionE] = useState(false);
  const handleReChange = (event) => {
    setReligion(event.target.value);
    const { value } = event.target;
    setReligionE(false);
    setFormData((prevData) => ({
      ...prevData,
      religion_id: value,
    }));
  };

  const [mTongue, setMTongue] = useState("");
  const [mTongueE, setMTongueE] = useState(false);
  const handlemTongueChange = (event) => {
    setMTongue(event.target.value);
    const { value } = event.target;
    setMTongueE(false);
    setFormData((prevData) => ({
      ...prevData,
      mother_tongue_id: value,
    }));
  };

  const [caste, setCaste] = useState("");
  const [casteE, setCasteE] = useState(false);
  const handleCasteChange = (event) => {
    setCaste(event.target.value);
    const { value } = event.target;
    setCasteE(false);
    setFormData((prevData) => ({
      ...prevData,
      cast: value,
    }));
  };

  const [subCaste, setSubCaste] = useState("");
  const [subCasteE, setSubCasteE] = useState(false);
  const handleSubCasteChange = (event) => {
    setSubCaste(event.target.value);
    const { value } = event.target;
    setSubCasteE(false);
    setFormData((prevData) => ({
      ...prevData,
      sub_cast: value,
    }));
  };

  const [gotra, setGotra] = useState("");
  const [gotraE, setGotraE] = useState(false);
  const handleGotraChange = (event) => {
    setGotra(event.target.value);
    const { value } = event.target;
    setGotraE(false);
    setFormData((prevData) => ({
      ...prevData,
      gotra: value,
    }));
  };

  const [manglik, setManglik] = useState("");
  const [manglikE, setManglikE] = useState(false);
  const handleManglikChange = (event) => {
    setManglik(event.target.value);
    const { value } = event.target;
    setManglikE(false);
    setFormData((prevData) => ({
      ...prevData,
      manglik_id: value,
    }));
  };

  const [education, setEducation] = useState("");
  const [educationE, setEducationE] = useState(false);
  const handleEducationChange = (event) => {
    setEducation(event.target.value);
    const { value } = event.target;
    setEducationE(false);
    setFormData((prevData) => ({
      ...prevData,
      education_id: value,
    }));
  };

  const [college, setCollege] = useState("");
  const [collegeE, setCollegeE] = useState(false);
  const handleCollegeChange = (event) => {
    setManglik(event.target.value);
    const { value } = event.target;
    setCollegeE(false);
    setFormData((prevData) => ({
      ...prevData,
      college_name: value,
    }));
  };

  const [employee, setEmployee] = useState("");
  const [employeeE, setEmployeeE] = useState(false);
  const handleEmpChange = (event) => {
    setEmployee(event.target.value);
    const { value } = event.target;
    setEmployeeE(false);
    setFormData((prevData) => ({
      ...prevData,
      employed_in_id: value,
    }));
  };

  const [organization, setOrganization] = useState("");
  const [organizationE, setOrganizationE] = useState(false);
  const handleOrganizationChange = (event) => {
    setEmployee(event.target.value);
    const { value } = event.target;
    setOrganizationE(false);
    setFormData((prevData) => ({
      ...prevData,
      organization_name: value,
    }));
  };

  const [income, setIncome] = useState("");
  const [incomeE, setIncomeE] = useState(false);
  const handleIncomeChange = (event) => {
    setIncome(event.target.value);
    const { value } = event.target;
    setIncomeE(false);
    setFormData((prevData) => ({
      ...prevData,
      annual_income_id: value,
    }));
  };

  const [fName, setFName] = useState("");
  const [fNameE, setFNameE] = useState(false);
  const handleFNameChange = (event) => {
    setFName(event.target.value);
    const { value } = event.target;
    setFNameE(false);
    setFormData((prevData) => ({
      ...prevData,
      father_name: value,
    }));
  };

  const [mName, setMName] = useState("");
  const [mNameE, setMNameE] = useState(false);
  const handleMNameChange = (event) => {
    setMName(event.target.value);
    setMNameE(false);
    const { value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      mother_name: value,
    }));
  };

  const [fOccupation, setFOccupation] = useState("");
  const [fOccupationE, setFOccupationE] = useState(false);
  const handleFempChange = (event) => {
    setFOccupation(event.target.value);
    const { value } = event.target;
    setFOccupationE(false);
    setFormData((prevData) => ({
      ...prevData,
      father_employed_in_id: value,
    }));
  };

  const [mOccupation, setMOccupation] = useState("");
  const [mOccupationE, setMOccupationE] = useState(false);
  const handleMempChange = (event) => {
    setMOccupation(event.target.value);
    const { value } = event.target;
    setMOccupationE(false);
    setFormData((prevData) => ({
      ...prevData,
      mother_employed_in_id: value,
    }));
  };

  const [brothers, setBrothers] = useState("");
  const [brothersE, setBrothersE] = useState(false);
  const handleBChange = (event) => {
    setBrothers(event.target.value);
    const { value } = event.target;
    setBrothersE(false);
    setFormData((prevData) => ({
      ...prevData,
      total_brother: value,
    }));
    if (parseInt(value) < parseInt(formData.married_brother)) {
      setMaxBrotherE(true);
    }
    if (parseInt(value) > parseInt(formData.married_brother)) {
      setMaxBrotherE(false);
    }
    if (parseInt(value) == parseInt(formData.married_brother)) {
      setMaxBrotherE(false);
    }
  };

  const [sisters, setSisters] = useState("");
  const [sistersE, setSistersE] = useState(false);
  const handleSChange = (event) => {
    setSisters(event.target.value);
    const { value } = event.target;
    setSistersE(false);
    setFormData((prevData) => ({
      ...prevData,
      total_sister: value,
    }));
    if (parseInt(value) < parseInt(formData.married_sister)) {
      setMaxSisterE(true);
    }
    if (parseInt(value) > parseInt(formData.married_sister)) {
      setMaxSisterE(false);
    }
    if (parseInt(value) == parseInt(formData.married_sister)) {
      setMaxSisterE(false);
    }
  };

  const [mBrothers, setmBrothers] = useState("");
  const [mBrothersE, setmBrothersE] = useState(false);
  const [maxBrotherE, setMaxBrotherE] = useState(false);
  const handleMBChange = (event) => {
    setmBrothers(event.target.value);
    const { value } = event.target;
    setmBrothersE(false);
    setMaxBrotherE(false);
    setFormData((prevData) => ({
      ...prevData,
      married_brother: value,
    }));
    if (parseInt(value) > parseInt(formData.total_brother)) {
      setMaxBrotherE(true);
    }
    if (parseInt(value) < parseInt(formData.total_brother)) {
      setMaxBrotherE(false);
    }
  };

  const handleMBBlur = () => {
    if (parseInt(formData.married_brother) > parseInt(formData.total_brother)) {
      setMaxBrotherE(true);
    } else {
      setMaxBrotherE(false);
    }
  };

  const [mSisters, setmSisters] = useState("");
  const [mSistersE, setmSistersE] = useState(false);
  const [maxSisterE, setMaxSisterE] = useState(false);
  const handleMSChange = (event) => {
    setmSisters(event.target.value);
    const { value } = event.target;
    setmSistersE(false);
    setMaxSisterE(false);
    setFormData((prevData) => ({
      ...prevData,
      married_sister: value,
    }));
    if(parseInt(value) > parseInt(formData.total_sister)){
      setMaxSisterE(true);
    }
    if(parseInt(value) < parseInt(formData.total_sister)){
      setMaxSisterE(false);
    }
  };

  const handleMSBlur = () => {
    if (parseInt(formData.married_sister) > parseInt(formData.total_sister)) {
      setMaxSisterE(true);
    } else {
      setMaxSisterE(false);
    }
  };

  const [contactNo, setcontactNo] = useState("");
  const [contactNoE, setcontactNoE] = useState(false);
  const handlecontactNoChange = (event) => {
    // setcontactNo(event.target.value);
    const { value } = event.target;
    setcontactNoE(false);
    if (/^\d*$/.test(value) && value.length <= 10) {
      setcontactNo(event.target.value);
      setFormData((prevData) => ({
        ...prevData,
        contact_number: value,
      }));
    }
  };

  const [email, setEmail] = useState("");
  const [emailE, setEmailE] = useState(false);
  const [emailE1, setEmailE1] = useState(false);
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    const { value } = event.target;
    setEmailE(false);
    setFormData((prevData) => ({
      ...prevData,
      email_id: value,
    }));
  };

  const [addressLine1, setaddressLine1] = useState("");
  const [addressE, setaddressE] = useState(false);
  const handleaddress1Change = (event) => {
    setaddressLine1(event.target.value);
    const { value } = event.target;
    setaddressE(false);
    setFormData((prevData) => ({
      ...prevData,
      addressLine1: value,
    }));
  };

  const [addressLine2, setaddressLine2] = useState("");
  const handleaddress2Change = (event) => {
    setaddressLine2(event.target.value);
    const { value } = event.target;
    setaddressE(false);
    setFormData((prevData) => ({
      ...prevData,
      addressLine2: value,
    }));
  };

  

  const [isLargeScreen, setIsLargeScreen] = React.useState(false);

  const handleResize = () => {
    setIsLargeScreen(window.innerWidth >= 600);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //handle file uplaod
  const [file, setFile] = useState(null);
  const [fileE, setFileE] = useState("");

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    console.log(selectedFile);
    setFileE("");
    if (selectedFile) {
      const fileType = selectedFile.type;
      if (fileType !== "image/jpeg" && fileType !== "image/png") {
        setFileE(t("please upload valid image formet(.jpg/.png)"));
        return;
      }
      setFile(selectedFile);
      setFormData((prevData) => ({
        ...prevData,
        image: selectedFile,
      }));
    }
  };

  const handleButtonClick = (event) => {
    event.preventDefault(); // Prevent scrolling to the top
    document.querySelector('input[type="file"]').click();
  };

  const handleClearFile = () => {
    setFile(null);
    console.log("clear");
  };

  const handleChange1 = (section) => (event) => {
    setActiveSection(section);
  };

  function validateForm() {
    if (!formData.date?.length) {
      setDateE(true);
    }
    if (!formData.month?.length) {
      setMonthE(true);
    }
    if (!formData.year?.length) {
      setYearE(true);
    }
    if (!formData.hour?.length) {
      setHourE(true);
    }
    if (!formData.minute?.length) {
      setMinE(true);
    }
    if (!formData.seconds?.length) {
      setSecE(true);
    }
    if (!formData.am?.length) {
      setAME(true);
    }
    if (formData.name.length === 0) {
      console.log("handle it");
      setNameE(true);
    }
    if (formData.created_for_id.length === 0) {
      setSelectedByE(true);
    }

    if(selectedLanguage.length === 0){
      setSelectedByLang(true)
    }

    if (formData.gander.length === 0) {
      setGenderE(true);
    }

    if (formData.place_of_birth.length === 0) {
      setBirthE(true);
    }

    if (formData.rashi_id.length === 0) {
      setRashiE(true);
    }

    if (formData.nakshatra_id.length === 0) {
      setNakshatraE(true);
    }

    if (formData.height_id.length === 0) {
      setHeightE(true);
    }

    if (formData.marital_status_id.length === 0) {
      setMStatusE(true);
    }

    if (formData.religion_id.length === 0) {
      setReligionE(true);
    }

    if (formData.mother_tongue_id.length === 0) {
      setMTongueE(true);
    }

    if (formData.cast.length === 0) {
      setCasteE(true);
    }

    if (formData.sub_cast.length === 0) {
      setSubCasteE(true);
    }
    if (formData.gotra.length === 0) {
      setGotraE(true);
    }
    if (formData.manglik_id.length === 0) {
      setManglikE(true);
    }
    if (formData.education_id.length === 0) {
      setEducationE(true);
    }
    if (formData.college_name.length === 0) {
      setCollegeE(true);
    }
    if (formData.employed_in_id.length === 0) {
      setEmployeeE(true);
    }
    if (formData.organization_name.length === 0) {
      setOrganizationE(true);
    }
    if (formData.annual_income_id.length === 0) {
      setIncomeE(true);
    }
    if (formData.father_name.length === 0) {
      setFNameE(true);
    }
    // if(formData.image === null){
    //   setFileE(true);
    // }
    // if (formData.father_employed_in_id.length === 0) {
    //   setFOccupationE(true);
    // }
    if (formData.mother_name.length === 0) {
      setMNameE(true);
    }
    // if (formData.mother_employed_in_id.length === 0) {
    //   setMOccupationE(true);
    // }
    if (formData.total_brother.length === 0) {
      setBrothersE(true);
    }
    if (formData.total_sister.length === 0) {
      setSistersE(true);
    }
    // if (formData.married_brother.length === 0) {
    //   setmBrothersE(true);
    // }
    // if(formData.married_brother > formData.total_brother){
    //   console.log("max exists");
    //   setMaxBrotherE(true);
    // }
    if (parseInt(formData.married_brother) > parseInt(formData.total_brother)) {
      setMaxBrotherE(true);
    }
    if (parseInt(formData.married_brother) < parseInt(formData.total_brother)) {
      setMaxBrotherE(false);
    }
    // if (formData.married_sister.length === 0) {
    //   setmSistersE(true);
    // }
    // if(formData.married_sister > formData.total_sister){
    //   console.log("max exists");
    //   setMaxSisterE(true);
    // }
    if(parseInt(formData.married_sister) > parseInt(formData.total_sister)){
      setMaxSisterE(true);
    }
    if(parseInt(formData.married_sister) < parseInt(formData.total_sister)){
      setMaxSisterE(false);
    }
    if (formData.contact_number.length === 0) {
      setcontactNoE(true);
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex pattern for email validation
    if (
      formData.email_id.length === 0 ||
      !emailPattern.test(formData.email_id)
    ) {
      setEmailE(true);
    }
    // if (formData.email_id == emailPattern) {
    //   setEmailE(true);
    // }
    if (!formData.addressLine1?.length) {
      console.log(formData.addressLine1);
      setaddressE(true);
    }
    // if (formData.date_of_birth.length === 0) {
    //   setDateBirthE(true);
    // }
    if (formData.time_of_birth.length === 0) {
      setTimeBirthE(true);
    }
    if (formData.image === null) {
      setFileE(t("Please Select Image for your Biodata"));
    }

    if (!formData.templates_id?.length) {
      setTemplateE(true);
    }
  }

  function handle() {
    const data = fetchBiodataTemplates();
    console.log(data);
  }

  const [imageNew, setImageNew] = useState(null);
  const [titleImageNew, setTitleImageNew] = useState(null);
  const [croppedArea, setCroppedArea] = useState(null);
  const [croppedTitleImageArea, setCroppedTitleImageArea] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedTitleImage, setSelectedTitleImage] = useState(null);
  const inputRef = useRef();
  const inputTitleRef = useRef()
  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleTitleImageClick = () => {
    inputTitleRef.current.click();
  };

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    console.log(croppedAreaPercentage, croppedAreaPixels);
    setCroppedArea(croppedAreaPixels);
  };

  const onTitleCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    console.log(croppedAreaPercentage, croppedAreaPixels);
    setCroppedTitleImageArea(croppedAreaPixels);
  };

  const triggerFileSelectPopup = () => inputRef.current.click();

  const onSelectFile = (event) => {
    setFileE("");
    if (event.target.files && event.target.files.length > 0) {
      setModalIsOpen(true);
      setSelectedImage(null);
      const render = new FileReader();
      render.readAsDataURL(event.target.files[0]);
      render.addEventListener("load", () => {
        console.log(render.result);
        setImageNew(render.result);
      });
    }
  };

  const onSelectTitleFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setModalIsOpen(true);
      setSelectedTitleImage(null);
      const render = new FileReader();
      render.readAsDataURL(event.target.files[0]);
      render.addEventListener("load", () => {
        console.log(render.result);
        setTitleImageNew(render.result);
      });
    }
  };

  const handleSelectCroppedImage = () => {
    if (imageNew) {
      // Create a canvas to draw the cropped image
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Set canvas dimensions to match the cropped area
      canvas.width = crop.width;
      canvas.height = crop.height;

      // Draw the cropped image onto the canvas
      ctx.drawImage(
        imageNew, // Ensure imageNew is not null
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        0,
        0,
        crop.width,
        crop.height
      );

      // Convert the canvas content to a data URL representing the cropped image
      const croppedImageUrl = canvas.toDataURL("image/jpeg");

      // Update the state with the selected cropped image URL
      setSelectedImage(croppedImageUrl);
    }
  };

  const onDownload = async () => {
    const { imageURL, imageBlob } = await generateDownload(
      imageNew,
      croppedArea
    );
    setSelectedImage(imageURL); // Set the cropped image URL to display it
    setFormData((prevData) => ({
      ...prevData,
      image: imageBlob, // Set formData image to the cropped image URL
    }));
    setImageNew(null);
  };

  const onTitleDownload = async () => {
    const { imageURL, imageBlob } = await generateDownload(
      titleImageNew,
      croppedTitleImageArea
    );
    setSelectedTitleImage(imageURL); // Set the cropped image URL to display it
    setFormData((prevData) => ({
      ...prevData,
      title_image: imageBlob, // Set formData image to the cropped image URL
    }));
    setTitleImageNew(null);
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Function to handle opening the modal
  const openModal = () => {
    setModalIsOpen(true);
  };

  const renderTitle = () => {
    if(selectedLanguage == 'en'){
      return (
        <h1 style={{fontFamily: "Enoway",
        color: "#EC0673",
        fontSize: 35,
        marginBottom: 10, 
        fontWeight: 400,
            letterSpacing: "0.1rem",}}>
            
              <span style={{color: "black"}}>{t("Start building")}</span> {t('Biodata')}
            </h1>
      )
    }
    else if(selectedLanguage == 'hi'){
      return (
        <h1 style={{fontFamily: "Enoway",
        color: "#EC0673",
        fontSize: 35,
        marginBottom: 10, 
        fontWeight: 400,
            letterSpacing: "0.1rem",}}>
            
            {t('बायोडाटा')} <span style={{color: "black"}}>{t("तैयार करें")}</span> 
            </h1>
      )
    } 
    else if(selectedLanguage == 'gu'){
      return (
        <h1 style={{fontFamily: "Enoway",
        color: "#EC0673",
        fontSize: 35,
        marginBottom: 10, 
        fontWeight: 400,
            letterSpacing: "0.1rem",}}>
            {t('બાયોડેટા')} <span style={{color: "black"}}>{t("તૈયાર કરો")}</span> 
            </h1>
      ) 
    }
  }
  const renderThemeTitle = ()=>{
    if(selectedLanguage == 'en'){
      return (
        <Typography
        style={{fontFamily: "Enoway",
        color: "#EC0673",
        fontSize: 33,
        fontWeight: "bold", 
        fontWeight: 400,
            letterSpacing: "0.1rem",}}
      >
         <span style={{color: "black"}}>{t("Select theme for")}</span> {t("Biodata")}
      </Typography>
      )
    }
    else if(selectedLanguage == 'gu'){
      return (
        <Typography
        style={{fontFamily: "Enoway",
        color: "#EC0673",
        fontSize: 33,
        fontWeight: "bold", 
        fontWeight: 400,
            letterSpacing: "0.1rem",}}
      >
         {t('બાયોડેટા')} <span style={{color: "black"}}>{t("માટે એક થીમ પસંદ કરો.")}</span>
      </Typography>
      )
    } 
    else if(selectedLanguage == 'hi'){
      return (
        <Typography
        style={{fontFamily: "Enoway",
        color: "#EC0673",
        fontSize: 33,
        fontWeight: "bold", 
        fontWeight: 400,
            letterSpacing: "0.1rem",}}
      >
         {t("बायोडाटा")} <span style={{color: "black"}}>{t("के लिए एक थीम चुनें।")}</span>
      </Typography>
      ) 
    }
  }
  // Function to handle closing the modal
  const closeModal = () => {
    setModalIsOpen(false);
    setImageNew(null);
  };

  return (
    <Grid container sx={{  display: "flex", justifyContent: "center", backgroundColor: "rgb(255, 210, 235)",  margin: "0 auto" }}>

    <Grid
      container
      style={{
        display: "flex",
        flexDirection: "column",
        // overflow: "visible",
        padding:  window.innerWidth > 1400 ? "30px 0" : "30px 7% 30px 7%",
        backgroundColor: "rgb(255, 210, 235)",
        fontWeight: "BOLDER",
        maxWidth: "1400px",
        maxHeight: "5000px",
      }}
    >
      <form style={{}} onSubmit={handleSubmit}>
      {renderTitle()}
        <h5
          
          style={{ fontFamily: "Avenir",
          color: "BLACK",
          fontSize: 15,
          fontWeight: "lighter",
          marginBottom: 50,}}
        >
          {t('ByProviding')}
        </h5>

        <Grid
          container
          spacing={1}
          style={{
            marginLeft: 0,
            border: "1px solid white",
            backgroundColor: "white",
            padding: "20px 7%",
            borderRadius: 10,
          }}
        >
          <Grid
            item
            xs={12}
            md={4}
            sm={12}
            style={{
              height: window.innerWidth > 900  ? 86 : 60,
              //   marginTop: isLargeScreen ? 0 : 30,
              marginRight: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              style={{fontFamily: "Enoway",
              textAlign: "left",
              fontSize: window.innerWidth > 500 ? "28px" : "20px",
              color: "#EC0673",
              fontWeight: "bolder", 
              fontWeight: 400,
              letterSpacing: "0.1rem",}}
            >
              {t("Select Language")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={7.18}
            style={{
              height: window.innerWidth > 900  ? 86 : 60,
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <CssTextField
              label={t("Select one")}
              // variant=""
              error={Boolean(selectedByLang)} // Pass error state to indicate validation status
              helperText={selectedByLang ? t("Please enter this Field" ): null}
              select
              fullWidth
              size="small"
              
              value={selectedLanguage}
              onChange={handleLanguage}
              InputLabelProps={{
                style: {
                  color: "lightgrey",
                },
              }}
              FormHelperTextProps={{ 
                style: {
                  color: "red", 
                  fontSize: "12px", 
                  textAlign: "left", 
                  marginTop: -2
                },
              }}
              InputProps={{
                style: {
                  //   color: "grey",
                  textAlign: "left",
                },
                endAdornment: <div style={{ color: "white" }} />,
              }}
              style={{
                width: isLargeScreen ? "100%" : "100%",
                alignContent: "left",
                alignSelf: "left",
                fontWeight: "bold",
                boxSizing: "border-box",
              
              }}
              aria-hidden={true}
            >
              {languages.map((item , index) => (
                
                <MenuItem key={index} value={item.key}  onClick={()=>changeLanguage(index.key)}>
                  {item.value}
                  {console.log("yes",selectedLanguage == item.key)}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            sm={12}
            style={{
              height: window.innerWidth > 900  ? 86 : 60,
              //   marginTop: isLargeScreen ? 0 : 30,
              marginRight: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              style={{fontFamily: "Enoway",
              textAlign: "left",
              fontSize: window.innerWidth > 500 ? "28px" : "20px",
              color: "#EC0673",
              fontWeight: "bolder", 
              fontWeight: 400,
              letterSpacing: "0.1rem",}}
            >
              {t("Biodata Created for")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={7.18}
            style={{
              height: window.innerWidth > 900  ? 86 : 60,
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <CssTextField
              label={t("Select one")}
              // variant=""
              error={Boolean(selectedByE)} // Pass error state to indicate validation status
              helperText={selectedByE ? t("Please enter this Field") : null}
              select
             
              fullWidth
              
              size="small"
              value={formData.created_for_id}
              onChange={handleByChange}
              InputLabelProps={{
                style: {
                  color: "lightgrey",
                },
              }}
              FormHelperTextProps={{ 
                style: {
                  color: "red", 
                  fontSize: "12px", 
                  textAlign: "left", 
                  marginTop: -2
                },
              }}
              InputProps={{
                style: {
                  //   color: "grey",
                  textAlign: "left",
                },
                endAdornment: <div style={{ color: "white" }} />,
              }}
              style={{
                width: isLargeScreen ? "100%" : "100%",
                alignContent: "left",
                alignSelf: "left",
                fontWeight: "bold",
                boxSizing: "border-box",
              
              }}
            >
              {createdforOp?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </CssTextField>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            sm={12}
            style={{
              height: window.innerWidth > 900  ? 86 : 60,
              //   marginTop: isLargeScreen ? 0 : 30,
              marginRight: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              style={{fontFamily: "Enoway",
              textAlign: "left",
              fontSize: window.innerWidth > 500 ? "28px" : "20px",
              color: "#EC0673",
              fontWeight: "bolder", 
              fontWeight: 400,
              letterSpacing: "0.1rem",}}
            >
              {t("Select Title Image")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={7.18}
            container
              spacing={2}
              style={{
              marginTop: isLargeScreen ? 20 : 20,
                // paddingLeft: 22,
               
              }}
              justifyContent="center"
          >
               <Grid
                container
                style={{
                  // height: isLargeScreen ? 86 : 86,
// backgroundColor:"blue",
                  display: "flex",
                  alignItems: "center",
                  justifyContent:"center",
                  justifyItems:"center",

                }}
              >
                  <Grid container style={{ padding: "1% !important", display: "flex", justifyContent: "center" }}>
                    <div className="container-cropper">
                      {titleImageNew ? (
                        <>
                          <div>
                            <Modal
                              isOpen={modalIsOpen}
                              onRequestClose={closeModal}
                              contentLabel="Crop Image Modal"
                              ariaHideApp={false}
                              style={{
                                overlay: {
                                  position: "fixed",
                                  top: 0,
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                                  zIndex: 9999, // Make sure this is higher than other elements
                                },
                                content: {
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  width: "100%",
                                  maxWidth: "550px",
                                  maxHeight: "100%",
                                  // overflow: "auto",
                                  backgroundColor: "white",
                                  borderRadius: "5px",
                                  padding: "20px",
                                  zIndex: 10000, // Make sure this is higher than other elements
                                },
                              }}
                            >
                              <div>
                                <Cropper
                                  style={{ width: "100%", height: "80vh" }}
                                  image={titleImageNew}
                                  crop={crop}
                                  zoom={zoom}
                                  aspect={1}
                                  onCropChange={setCrop}
                                  onZoomChange={setZoom}
                                  onCropComplete={onTitleCropComplete}
                                />
                                {/* Add any other controls/buttons here */}
                                <Button
                                  style={{
                                    backgroundColor: "white",
                                    width: "30px",
                                  }}
                                  onClick={closeModal}
                                >
                                  <MdCancel
                                    style={{ fontSize: "20px", color: "red" }}
                                  />
                                </Button>
                                <Button
                                  style={{
                                    backgroundColor: "white",
                                    width: "30px",
                                  }}
                                  onClick={onTitleDownload}
                                >
                                  <BiSolidSelectMultiple
                                    style={{ fontSize: "20px" }}
                                  />
                                </Button>
                              </div>
                            </Modal>
                          </div>
                        </>
                      ) : (
                        !selectedTitleImage && (
                          <div
                            className="container-buttons"
                            onClick={handleTitleImageClick}
                            style={{ display: "flex", justifyContent: "center" }}
                          >
                            <img
                              src={ImagePath.ImageUpload}
                              style={{ width: window.innerWidth > 500 ? "10%" : "20%", cursor: "pointer" }}
                              alt="Upload"
                            />
                            <input
                              type="file"
                              accept="image/*"
                              ref={inputTitleRef}
                              onChange={onSelectTitleFile}
                              style={{ display: "none" }}
                            />
                          </div>
                        )
                      )}
                    </div>
                    {selectedTitleImage && (
                      <>
                        <div
                          style={{ border: "1px solid white", marginBottom: 30, display: "flex", justifyContent: "center", marginTop: -10 }}
                        >
                          <div
                            onClick={handleTitleImageClick}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",
                              position: "relative", // Added relative positioning
                              width: "100px",
                              height:"100px" // Set a width to ensure consistent positioning
                            }}
                          >
                            <img
                              style={{ width: "100%" }}
                              src={selectedTitleImage}
                              alt="Selected Cropped Image"
                            />
                            <button
                              style={{
                                backgroundColor: "blueviolet",
                                height: "30px",
                                borderRadius: "50%",
                                position: "absolute", // Changed to absolute positioning
                                border: "none",
                                width: "30px",
                                cursor: "pointer",
                                bottom: "-10px", // Positioned at the bottom
                                right: "-10px", // Slightly outside the image to the right
                              }}
                            >
                              <FaPencilAlt
                                style={{ color: "white", fontSize: 15 }}
                              />
                            </button>
                            <input
                              type="file"
                              accept="image/*"
                              ref={inputTitleRef}
                              onChange={onSelectTitleFile}
                              style={{ display: "none" }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </Grid>
              </Grid>
              <Grid
                container
                // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                style={{
                  // paddingLeft: 16,
                  // paddingTop: 8,
                  justifyContent:"center",
                  display:"flex",
                  // flexDirection:"column"
                }}
                >
                {!selectedTitleImage && (
                  <>
                <Grid > 
                  <Typography
                     
                     style={{fontFamily: "Enoway",
                      textAlign: "left",
                      fontSize: window.innerWidth > 500 ? "28px" : "20px",
                      fontFamily: "Avenir",
                      textAlign: "left",
                      fontWeight: 500,
                      color: "black",
                    }}
                  >
                    {t("Upload Title Image")}
                  </Typography>
                </Grid>
                </>
              )}
              </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={7.18}
            style={{
              height: window.innerWidth > 900  ? 86 : 60,
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
              <CssTextField
                label={t("Title")}
                value={formData.title}
                size="small"
                onChange={handleTitleChange}
                style={{
                  width: isLargeScreen ? "100%" : "100%",
                  alignContent: "left",
                  alignSelf: "center",  // Center vertically
                  fontWeight: "bold",
                }}
                InputProps={{
                  style: {
                    color: "",
                    textAlign: "left",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "lightgrey",
                  },
                }}
                FormHelperTextProps={{
                  style: {
                    color: "red",
                    fontSize: "12px",
                    textAlign: "left",
                    marginTop: -2
                  },
                }}
              />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={7.18}
            style={{
              height: window.innerWidth > 900  ? 86 : 60,
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
              <CssTextField
                label={t("Sub Title")}
                value={formData.sub_title}
                size="small"
                onChange={handleSubTitleChange}
                style={{
                  width: isLargeScreen ? "100%" : "100%",
                  alignContent: "left",
                  alignSelf: "center",  // Center vertically
                  fontWeight: "bold",
                }}
                InputProps={{
                  style: {
                    color: "",
                    textAlign: "left",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "lightgrey",
                  },
                }}
                FormHelperTextProps={{
                  style: {
                    color: "red",
                    fontSize: "12px",
                    textAlign: "left",
                    marginTop: -2
                  },
                }}
              />
          </Grid>
         
          

        </Grid>

        <Accordion
          defaultExpanded
          expanded={activeSection === "personal"}
          style={{
            // backgroundColor: "red",
            margin: "30px 0px",
            borderRadius: activeSection === "personal" ? "10px 10px 10px 10px" : "10px 10px 10px 10px",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon style={{ color: "white", fontSize: 45 }} />
            }
            aria-controls="personal-details-content"
            id="personal-details-header"
            onClick={handleChange1("personal")}
            style={{
              //   backgroundColor: "rgba(124, 0, 31, 1)",
              backgroundColor: "#EC0673",
              borderRadius: activeSection === "personal" ? "10px 10px 0 0" : "10px 10px 10px 10px",
              padding: window.innerWidth > 500 ?"4px 40px" : "4px 10px" ,
            }}
          >
            <Typography
              style={fontStyle.FormHead1}
            >
              {t('Personal Details')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: "50px 7%" }}>
            {/* <Grid
              container
              spacing={2}
              style={{ paddingLeft: isLargeScreen ? 22 : 4, justifyContent:"center", display:"flex",backgroundColor:"red", alignContent:"center" }}
            >
             
              <Grid
                item
                xs={12}
                sm={6.8}
                md={7.18}
                style={{
                  height: isLargeScreen ? 85 : 60,
                  //   backgroundColor: "blue",
                  marginLeft: "auto",

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CssTextField
                  error={Boolean(nameE)} // Pass error state to indicate validation status
                  helperText={nameE ? "Please enter your Name" : null}
                  value={formData.name}
                  size="small"
                  onChange={handleNameChange}
                  style={{
                    width: isLargeScreen ? "100%" : "100%",
                    alignContent: "left",
                    alignSelf: "left",
                    fontWeight: "bold",
                  }}
                  InputProps={{
                    style: {
                      color: "",
                      textAlign: "left",
                    },
                  }}
                />
              </Grid>
            </Grid> */}

<Grid
  container
  spacing={2}
  style={{ 
    // paddingLeft: isLargeScreen ? 22 : 4, 
    justifyContent: "center", 
    display: "flex", 
    // backgroundColor:"red",
    alignContent: "center" 
  }}
>
  <Grid
    item
    xs={12}
    sm={6.8}
    md={7.18}
    style={{
      height: isLargeScreen ? 80 : 80,
      marginLeft: "auto",
      marginRight: "auto",  // Center horizontally
      display: "flex",
      alignItems: "center",
      justifyContent: "center",  // Center content inside the child Grid
      alignContent:"center"
    }}
  >
    <CssTextField
      error={Boolean(nameE)} // Pass error state to indicate validation status
      helperText={nameE ? t("Please enter your Name") : null}
      label={t("Enter Your Name")}
      value={formData.name}
      size="small"
      onChange={handleNameChange}
      style={{
        width: isLargeScreen ? "100%" : "100%",
        alignContent: "left",
        alignSelf: "center",  // Center vertically
        fontWeight: "bold",
      }}
      InputProps={{
        style: {
          color: "",
          textAlign: "left",
        },
      }}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2
        },
      }}
    />
  </Grid>
</Grid>

        
<Grid
  container
  spacing={2}
  style={{ 
    // paddingLeft: isLargeScreen ? 22 : 4, 
    justifyContent: "center", 
    display: "flex", 
    alignContent: "center" 
  }}
>
  <Grid
    item
    xs={12}
    sm={6.8}
    md={7.18}
    style={{
      height: isLargeScreen ? 80 : 80,
      marginLeft: "auto",
      marginRight: "auto",  // Center horizontally
      display: "flex",
      alignItems: "center",
      justifyContent: "center",  // Center content inside the child Grid
    }}
  >
    <CssTextField
      error={Boolean(genderE)} // Pass error state to indicate validation status
      helperText={genderE ? t("Please enter your Gender") : null}
      label={t("Select your Gender")}
      select
      
      size="small"
      id="gender"
      onClose={() => {
       console.log("calledclose");
       
        document.body.classList.remove("no-scroll");
      }}
      name="gender"
      fullWidth
      value={formData.gander}
      onChange={handleGenderChange}
      InputLabelProps={{
        style: {
          color: "lightgrey",
          zIndex: 1,
        },
      }}
      InputProps={{
        style: {
          color: "",
          textAlign: "left",
        },
        endAdornment: <div style={{ color: "white" }} />,
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2
        },
      }}
      style={{
        width: isLargeScreen ? "100%" : "100%",
        alignContent: "left",
        alignSelf: "center",
        fontWeight: "bold",
        boxSizing: "border-box",
      }}
    >
      {genderr.map((item, index) => (
        <MenuItem key={index} value={index}>
          {item}
        </MenuItem>
      ))}
    </CssTextField>
  </Grid>
</Grid>


<Grid
  container
  spacing={2}
  style={{  }}
>
  <Grid
    item
    xs={12}
    sm={6.8}
    md={7.18}
    style={{
      height: isLargeScreen ? 80 : 80,
      marginLeft: "auto",
      marginRight: "auto",  // Center horizontally
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    }}
  >
    <CssTextField
      error={Boolean(dateE)} // Pass error state to indicate validation status
      helperText={
        dateE ? (
         
            t("Enter Date")
          
        ) : null
      }
      label={t("Date")}
      variant="outlined"
      select
      size="small"
      fullWidth
      
      name="date"
      value={formData.date}
      onChange={handleChange}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      InputProps={{
        style: {
          textAlign: "left",
        },
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2,
          whiteSpace: "nowrap", // Prevent the text from wrapping into multiple lines
        },
      }}
      style={{
        width: "33.33%",
        fontWeight: "bold",
        boxSizing: "border-box",
        marginRight: 5,
      }}
    >
      {dateField}
    </CssTextField>
    <CssTextField
      error={Boolean(monthE)}
      helperText={
        monthE ? (
          
            t("Enter Month")
          
        ) : null
      }
      label={t("Month")}
      variant="outlined"
      select
      fullWidth
      
      size="small"
      name="month"
      value={formData.month}
      onChange={handleChangeM}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      InputProps={{
        style: {
          textAlign: "left",
        },
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2,
          whiteSpace: "nowrap", // Prevent the text from wrapping into multiple lines
        },
      }}
      style={{
        width: "33.33%",
        fontWeight: "bold",
        boxSizing: "border-box",
        marginRight: 5,
      }}
    >
      {monthField}
    </CssTextField>
    <CssTextField
      error={Boolean(yearE)}
      helperText={
        yearE ? (
          
            t("Enter Year")
          
        ) : null
      }
      label={t("Year")}
      variant="outlined"
      select
      
      fullWidth
      size="small"
      name="year"
      value={formData.year}
      onChange={handleChangeY}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      InputProps={{
        style: {
          textAlign: "left",
        },
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2,
          whiteSpace: "nowrap", // Prevent the text from wrapping into multiple lines
        },
      }}
      style={{
        width: "33.33%",
        fontWeight: "bold",
        boxSizing: "border-box",
      }}
    >
      {yearField}
    </CssTextField>
  </Grid>
</Grid>

<Grid
  container
  spacing={2}
  style={{ }}
>
  <Grid
    item
    xs={12}
    sm={6.8}
    md={7.18}
    style={{
      height: isLargeScreen ? 80 : 80,
      marginLeft: "auto",
      marginRight: "auto",  // Center horizontally
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    }}
  >
    <CssTextField
      error={Boolean(hourE)} // Pass error state to indicate validation status
      helperText={
        hourE ? (
          
            t("Enter Hour")
         
        ) : null
      }
      label={t("Hour")}
      variant="outlined"
      select
      
      fullWidth
      size="small"
      name="hour"
      value={formData.hour}
      onChange={handleChange2}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2,
          whiteSpace: "nowrap", // Prevent the text from wrapping into multiple lines
        },
      }}
      InputProps={{
        style: {
          textAlign: "left",
        },
      }}
      style={{
        width: "33.33%",
        fontWeight: "bold",
        boxSizing: "border-box",
        marginRight: 5,
      }}
    >
      {hourField}
    </CssTextField>
    <CssTextField
      error={Boolean(minE)} // Pass error state to indicate validation status
      helperText={
        minE ? (
          
            t("Enter Minute")
         
        ) : null
      }
      label={t("Minute")}
      variant="outlined"
      select
      
      fullWidth
      size="small"
      name="minute"
      value={formData.minute}
      onChange={handleChange2M}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2,
          whiteSpace: "nowrap", // Prevent the text from wrapping into multiple lines
        },
      }}
      InputProps={{
        style: {
          textAlign: "left",
        },
      }}
      style={{
        width: "33.33%",
        fontWeight: "bold",
        boxSizing: "border-box",
        marginRight: 5,
      }}
    >
      {minuteField}
    </CssTextField>
    <CssTextField
      error={Boolean(aME)} // Pass error state to indicate validation status
      helperText={
        aME ? 
            t("Enter Meridiem")
         : null
      }
t      label={("AM/PM")}
      variant="outlined"
      select
      
      fullWidth
      name="am"
      size="small"
      value={formData.am}
      onChange={(event) => {
        handleTimeChange(event);
        handleChange2A(event);
      }}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2,
          whiteSpace: "nowrap", // Prevent the text from wrapping into multiple lines
        },
      }}
      InputProps={{
        style: {
          textAlign: "left",
        },
      }}
      style={{
        width: "33.33%",
        fontWeight: "bold",
        boxSizing: "border-box",
      }}
    >
      {time.map((item) => (
        <MenuItem value={item} key={item}>
          {item}
        </MenuItem>
      ))}
    </CssTextField>
  </Grid>
</Grid>


<Grid
  container
  spacing={2}
  style={{  }}
>
  <Grid
    item
    xs={12}
    sm={6.8}
    md={7.18}
    style={{height: isLargeScreen ? 80 : 80,
      
      marginLeft: "auto",
      marginRight: "auto",  // Center horizontally
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    }}
  >
    <CssTextField
      error={Boolean(birthE)} // Pass error state to indicate validation status
      helperText={birthE ? t("Please enter your place of birth") : null}
      size="small"
      label={t("Enter your place of birth")}
      style={{
        width: "100%",
        alignContent: "left",
        alignSelf: "left",
        fontWeight: "bold",
        boxSizing: "border-box",
      }}
      InputProps={{
        style: {
          textAlign: "left",
        },
      }}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2
        },
      }}
      value={formData.place_of_birth}
      onChange={handlePlaceChange}
    />
  </Grid>
</Grid>


<Grid
  container
  spacing={2}
  style={{  }}
>
  <Grid
    item
    xs={12}
    sm={6.8}
    md={7.18}
    style={{
      height: isLargeScreen ? 80 : 80,
      marginLeft: "auto",
      marginRight: "auto",  // Center horizontally
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    }}
  >
    <CssTextField
      error={Boolean(rashiE)} // Pass error state to indicate validation status
      helperText={rashiE ? t("Please enter your Rashi") : null}
      label={t("Select your Rashi")}
      variant="outlined"
      select
      
      size="small"
      fullWidth
      value={formData.rashi_id}
      onChange={handleRashiChange}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      InputProps={{
        style: {
          textAlign: "left",
        },
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2
        },
      }}
      style={{
        width: "100%",
        alignContent: "left",
        alignSelf: "left",
        fontWeight: "bold",
        boxSizing: "border-box",
      }}
    >
      {rashiOp.map((item) => (
        <MenuItem value={item.id} key={item.id}>
          {item.name}
        </MenuItem>
      ))}
    </CssTextField>
  </Grid>
</Grid>


<Grid
  container
  spacing={2}
  style={{  }}
>
  <Grid
    item
    xs={12}
    sm={6.8}
    md={7.18}
    style={{
      height: isLargeScreen ? 80 : 80,
      marginLeft: "auto",
      marginRight: "auto",  // Center horizontally
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    }}
  >
    <CssTextField
      error={Boolean(nakshatraE)} // Pass error state to indicate validation status
      helperText={nakshatraE ? t("Please enter your Nakshatra") : null}
      label={t("Select your Nakshatra")}
      variant="outlined"
      
      select
      fullWidth
      size="small"
      value={formData.nakshatra_id}
      onChange={handleNakChange}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2
        },
      }}
      InputProps={{
        style: {
          textAlign: "left",
        },
      }}
      style={{
        width: "100%",
        alignContent: "left",
        alignSelf: "left",
        fontWeight: "bold",
        boxSizing: "border-box",
      }}
    >
      {nakshatraOp.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      ))}
    </CssTextField>
  </Grid>
</Grid>


<Grid
  container
  spacing={2}
  style={{  }}
>
  <Grid
    item
    xs={12}
    sm={6.8}
    md={7.18}
    style={{
      height: isLargeScreen ? 80 : 80,
      marginLeft: "auto",
      marginRight: "auto",  // Center horizontally
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    }}
  >
    <CssTextField
      error={Boolean(heightE)} // Pass error state to indicate validation status
      helperText={heightE ? t("Please enter your Height") : null}
      label={t("Select your height")}
      variant="outlined"
      select
      fullWidth
      size="small"
      
      value={formData.height_id}
      onChange={handleHChange}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      InputProps={{
        style: {
          textAlign: "left",
        },
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2
        },
      }}
      style={{
        width: "100%",
        alignContent: "left",
        alignSelf: "left",
        fontWeight: "bold",
        boxSizing: "border-box",
      }}
    >
      {heightOp.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      ))}
    </CssTextField>
  </Grid>
</Grid>

<Grid
  container
  spacing={2}
  style={{  }}
>
  <Grid
    item
    xs={12}
    sm={6.8}
    md={7.18}
    style={{
      height: isLargeScreen ? 80 : 80,
      marginLeft: "auto",
      marginRight: "auto",  // Center horizontally
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    }}
  >
    <CssTextField
      error={Boolean(mStatusE)} // Pass error state to indicate validation status
      helperText={mStatusE ? t("Please enter your Marital Status") : null}
      label={t("Select your Marital Status")}
      variant="outlined"
      select
      
      fullWidth
      size="small"
      value={formData.marital_status_id}
      onChange={handleMstatusChange}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      InputProps={{
        style: {
          textAlign: "left",
        },
      }}
      style={{
        width: "100%",
        alignContent: "left",
        alignSelf: "left",
        fontWeight: "bold",
        boxSizing: "border-box",
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2
        },
      }}
    >
      {maritalOp.map((index) => (
        <MenuItem key={index.id} value={index.id}>
          {index.name}
        </MenuItem>
      ))}
    </CssTextField>
  </Grid>
</Grid>

<Grid
  container
  spacing={2}
  style={{  }}
>
  <Grid
    item
    xs={12}
    sm={6.8}
    md={7.18}
    style={{
      height: isLargeScreen ? 80 : 80,
      marginLeft: "auto",
      marginRight: "auto",  // Center horizontally
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    }}
  >
    <CssTextField
      error={Boolean(religionE)} // Pass error state to indicate validation status
      helperText={religionE ? t("Please enter your Religion") : null}
      label={t("Select your Religion")}
      variant="outlined"
      select
      fullWidth
      size="small"
      
      value={formData.religion_id}
      onChange={handleReChange}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      InputProps={{
        style: {
          textAlign: "left",
        },
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2
        },
      }}
      style={{
        width: "100%",
        alignContent: "left",
        alignSelf: "left",
        fontWeight: "bold",
        boxSizing: "border-box",
      }}
    >
      {religionOp.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      ))}
    </CssTextField>
  </Grid>
</Grid>

<Grid
  container
  spacing={2}
  style={{  }}
>
  <Grid
    item
    xs={12}
    sm={6.8}
    md={7.18}
    style={{
      height: isLargeScreen ? 80 : 80,
      marginLeft: "auto",
      marginRight: "auto",  // Center horizontally
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    }}
  >
    <CssTextField
      error={Boolean(mTongueE)} // Pass error state to indicate validation status
      helperText={mTongueE ? t("Please enter your Mother Tongue") : null}
      label={t("Select your Mother Tongue")}
      variant="outlined"
      select
      
      fullWidth
      size="small"
      value={formData.mother_tongue_id}
      onChange={handlemTongueChange}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      InputProps={{
        style: {
          textAlign: "left",
        },
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2
        },
      }}
      style={{
        width: "100%",
        alignContent: "left",
        alignSelf: "left",
        fontWeight: "bold",
        boxSizing: "border-box",
      }}
    >
      {tongueOp.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      ))}
    </CssTextField>
  </Grid>
</Grid>

<Grid
  container
  spacing={2}
  style={{ justifyContent: "center" }}
>
  <Grid
    item
    xs={12}
    sm={6.8}
    md={7.18}
    style={{
      height: isLargeScreen ? 80 : 80,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    }}
  >
    <CssTextField
      error={Boolean(casteE)} // Pass error state to indicate validation status
      helperText={casteE ? t("Please enter your Cast") : null}
      size="small"
      label={t("Enter your caste")}
      style={{
        width: isLargeScreen ? "100%" : "100%",
        alignContent: "left",
        alignSelf: "left",
        fontWeight: "bold",
        boxSizing: "border-box",
      }}
      InputProps={{
        style: {
          //   color: "lightgrey",
          textAlign: "left",
        },
      }}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2
        },
      }}
      value={formData.cast}
      onChange={handleCasteChange}
    />
  </Grid>
</Grid>

<Grid
  container
  spacing={2}
  style={{  justifyContent: "center" }}
>
  <Grid
    item
    xs={12}
    sm={6.8}
    md={7.18}
    style={{
      height: isLargeScreen ? 80 : 80,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    }}
  >
    <CssTextField
      error={Boolean(subCasteE)} // Pass error state to indicate validation status
      helperText={subCasteE ? t("Please enter your Sub-Cast") : null}
      size="small"
      label={t("Enter your sub-caste")}
      style={{
        width: isLargeScreen ? "100%" : "100%",
        alignContent: "left",
        alignSelf: "left",
        fontWeight: "bold",
        boxSizing: "border-box",
      }}
      InputProps={{
        style: {
          //   color: "lightgrey",
          textAlign: "left",
        },
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2
        },
      }}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      value={formData.sub_cast}
      onChange={handleSubCasteChange}
    />
  </Grid>
</Grid>

<Grid
  container
  spacing={2}
  style={{  justifyContent: "center" }}
>
  <Grid
    item
    xs={12}
    sm={6.8}
    md={7.18}
    style={{
      height: isLargeScreen ? 80 : 80,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    }}
  >
    <CssTextField
      error={Boolean(gotraE)} // Pass error state to indicate validation status
      helperText={gotraE ? t("Please enter your Gotra") : null}
      size="small"
      label={t("Enter your gotra")}
      style={{
        width: isLargeScreen ? "100%" : "100%",
        alignContent: "left",
        alignSelf: "left",
        fontWeight: "bold",
        boxSizing: "border-box",
      }}
      InputProps={{
        style: {
          //   color: "lightgrey",
          textAlign: "left",
        },
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2
        },
      }}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      value={formData.gotra}
      onChange={handleGotraChange}
    />
  </Grid>
</Grid>

<Grid
  container
  spacing={2}
  style={{  justifyContent: "center" }}
>
  <Grid
    item
    xs={12}
    sm={6.8}
    md={7.18}
    style={{
      height: isLargeScreen ? 80 : 80,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    }}
  >
    <CssTextField
      error={Boolean(manglikE)} // Pass error state to indicate validation status
      helperText={manglikE ? t("Please enter your Manglik") : null}
      size="small"
      label={t("Are you Manglik")}
      variant="outlined"
      select
      
      fullWidth
      value={formData.manglik_id}
      onChange={handleManglikChange}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      InputProps={{
        style: {
          //   color: "lightgrey",
          textAlign: "left",
        },
      }}
      style={{
        width: isLargeScreen ? "100%" : "100%",
        alignContent: "left",
        alignSelf: "left",
        fontWeight: "bold",
        boxSizing: "border-box",
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2
        },
      }}
    >
      {manglikOp.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      ))}
    </CssTextField>
  </Grid>
</Grid>

<Grid
  container
  spacing={2}
  style={{ justifyContent: "center" }}
>
  <Grid
    item
    xs={12}
    sm={6.8}
    md={7.18}
    style={{
      height: isLargeScreen ? 80 : 80,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    }}
  >
    <CssTextField
      error={Boolean(educationE)} // Pass error state to indicate validation status
      helperText={educationE ? t("Please enter your Education") : null}
      label={t("Select your Education")}
      variant="outlined"
      select
      fullWidth
      size="small"
      
      value={formData.education_id}
      onChange={handleEducationChange}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2
        },
      }}
      InputProps={{
        style: {
          //   color: "lightgrey",
          textAlign: "left",
        },
      }}
      style={{
        width: isLargeScreen ? "100%" : "100%",
        alignContent: "left",
        alignSelf: "left",
        fontWeight: "bold",
        boxSizing: "border-box",
      }}
    >
      {educationOp.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      ))}
    </CssTextField>
  </Grid>
</Grid>

<Grid
  container
  spacing={2}
  style={{  justifyContent: "center" }}
>
  <Grid
    item
    xs={12}
    sm={6.8}
    md={7.18}
    style={{
      height: isLargeScreen ? 80 : 80,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    }}
  >
    <CssTextField
      // error={Boolean(collegeE)} // Pass error state to indicate validation status
      // helperText={collegeE ? t("Please enter your College Name") : null}
      size="small"
      label={t("Enter your college name")}
      style={{
        width: isLargeScreen ? "100%" : "100%",
        alignContent: "left",
        alignSelf: "left",
        fontWeight: "bold",
        boxSizing: "border-box",
      }}
      InputProps={{
        style: {
          //   color: "lightgrey",
          textAlign: "left",
        },
      }}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2
        },
      }}
      value={formData.college_name}
      onChange={handleCollegeChange}
    />
  </Grid>
</Grid>

<Grid
  container
  spacing={2}
  style={{  justifyContent: "center" }}
>
  <Grid
    item
    xs={12}
    sm={6.8}
    md={7.18}
    style={{
      height: isLargeScreen ? 80 : 80,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    }}
  >
    <CssTextField
      // error={Boolean(employeeE)} // Pass error state to indicate validation status
      // helperText={employeeE ? t("Please enter your Employee field") : null}
      label={t("Employed in")}
      variant="outlined"
      select
      fullWidth
      
      size="small"
      value={formData.employed_in_id}
      onChange={handleEmpChange}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2
        },
      }}
      InputProps={{
        style: {
          //   color: "lightgrey",
          textAlign: "left",
        },
      }}
      style={{
        width: isLargeScreen ? "100%" : "100%",
        alignContent: "left",
        alignSelf: "left",
        fontWeight: "bold",
        boxSizing: "border-box",
      }}
    >
      {employeeOp.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      ))}
    </CssTextField>
  </Grid>
</Grid>

<Grid
  container
  spacing={1}
  style={{  justifyContent: "center" }}
>
  <Grid
    item
    xs={12}
    sm={6.7}
    md={7.14}
    style={{
      height: isLargeScreen ? 80 : 80,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    }}
  >
    <CssTextField
      // error={Boolean(organizationE)} // Pass error state to indicate validation status
      // helperText={
      //   organizationE ? t("Please enter your Organization Name") : null
      // }
      label={t("Enter your organization name")}
      size="small"
      style={{
        width: "100%",
        alignContent: "left",
        alignSelf: "left",
        fontWeight: "bold",
        boxSizing: "border-box",
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2
        },
      }}
      InputProps={{
        style: {
          //   color: "lightgrey",
          textAlign: "left",
        },
      }}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      value={formData.organization_name}
      onChange={handleOrganizationChange}
    />
  </Grid>
</Grid>

<Grid
  container
  spacing={2}
  style={{  justifyContent: "center" }}
>
  <Grid
    item
    xs={12}
    sm={6.8}
    md={7.18}
    style={{
      height: isLargeScreen ? 80 : 80,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    }}
  >
    <CssTextField
      error={Boolean(incomeE)} // Pass error state to indicate validation status
      helperText={incomeE ? t("Please enter your Annual Income") : null}
      label={t("Annual Income")}
      variant="outlined"
      select
      size="small"
      fullWidth
      
      value={formData.annual_income_id}
      onChange={handleIncomeChange}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      InputProps={{
        style: {
          //   color: "lightgrey",
          textAlign: "left",
        },
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2
        },
      }}

      style={{
        width: isLargeScreen ? "100%" : "100%",
        alignContent: "left",
        alignSelf: "left",
        fontWeight: "bold",
        boxSizing: "border-box",
      }}
    >
      {annualIncome.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      ))}
    </CssTextField>
  </Grid>
</Grid>


            <Grid
              container
              spacing={2}
              style={{
              marginTop: isLargeScreen ? 20 : 20,
                // paddingLeft: 22,
               
              }}
              justifyContent="center"
            >
              
              <Grid
                container
                style={{
                  // height: isLargeScreen ? 86 : 86,
// backgroundColor:"blue",
                  display: "flex",
                  alignItems: "center",
                  justifyContent:"center",
                  justifyItems:"center",

                }}
              >
                  <Grid container style={{ padding: "1% !important", display: "flex", justifyContent: "center" }}>
                    <div className="container-cropper">
                      {imageNew ? (
                        <>
                          <div>
                            <Modal
                              isOpen={modalIsOpen}
                              onRequestClose={closeModal}
                              contentLabel="Crop Image Modal"
                              ariaHideApp={false}
                              style={{
                                overlay: {
                                  position: "fixed",
                                  top: 0,
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                                  zIndex: 9999, // Make sure this is higher than other elements
                                },
                                content: {
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  width: "100%",
                                  maxWidth: "550px",
                                  maxHeight: "100%",
                                  // overflow: "auto",
                                  backgroundColor: "white",
                                  borderRadius: "5px",
                                  padding: "20px",
                                  zIndex: 10000, // Make sure this is higher than other elements
                                },
                              }}
                            >
                              <div>
                                <Cropper
                                  style={{ width: "100%", height: "80vh" }}
                                  image={imageNew}
                                  crop={crop}
                                  zoom={zoom}
                                  aspect={1}
                                  onCropChange={setCrop}
                                  onZoomChange={setZoom}
                                  onCropComplete={onCropComplete}
                                />
                                {/* Add any other controls/buttons here */}
                                <Button
                                  style={{
                                    backgroundColor: "white",
                                    width: "30px",
                                  }}
                                  onClick={closeModal}
                                >
                                  <MdCancel
                                    style={{ fontSize: "20px", color: "red" }}
                                  />
                                </Button>
                                <Button
                                  style={{
                                    backgroundColor: "white",
                                    width: "30px",
                                  }}
                                  onClick={onDownload}
                                >
                                  <BiSolidSelectMultiple
                                    style={{ fontSize: "20px" }}
                                  />
                                </Button>
                              </div>
                            </Modal>
                          </div>
                        </>
                      ) : (
                        !selectedImage && (
                          <div
                            className="container-buttons"
                            onClick={handleImageClick}
                            style={{ display: "flex", justifyContent: "center" }}
                          >
                            <img
                              src={ImagePath.ImageUpload}
                              style={{ width: window.innerWidth > 500 ? "10%" : "20%", cursor: "pointer" }}
                              alt="Upload"
                            />
                            <input
                              type="file"
                              accept="image/*"
                              ref={inputRef}
                              onChange={onSelectFile}
                              style={{ display: "none" }}
                            />
                          </div>
                        )
                      )}
                    </div>
                    {selectedImage && (
                      <>
                        <div
                          style={{ border: "1px solid white", marginBottom: 30, display: "flex", justifyContent: "center", marginTop: -10 }}
                        >
                          <div
                            onClick={handleImageClick}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              alignItems: "center",
                              position: "relative", // Added relative positioning
                              width: "100px",
                              height:"100px" // Set a width to ensure consistent positioning
                            }}
                          >
                            <img
                              style={{ width: "100%" }}
                              src={selectedImage}
                              alt="Selected Cropped Image"
                            />
                            <button
                              style={{
                                backgroundColor: "blueviolet",
                                height: "30px",
                                borderRadius: "50%",
                                position: "absolute", // Changed to absolute positioning
                                border: "none",
                                width: "30px",
                                cursor: "pointer",
                                bottom: "-10px", // Positioned at the bottom
                                right: "-10px", // Slightly outside the image to the right
                              }}
                            >
                              <FaPencilAlt
                                style={{ color: "white", fontSize: 15 }}
                              />
                            </button>
                            <input
                              type="file"
                              accept="image/*"
                              ref={inputRef}
                              onChange={onSelectFile}
                              style={{ display: "none" }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </Grid>
                <Grid container style={{ justifyContent:"center",display:'flex', margin: 0}}>
                {fileE && <Typography style={{ color: "red", textAlign:"center", justifySelf:"center", fontSize:"0.7rem" }}>{t(fileE)}</Typography>}
                </Grid>
              </Grid>
              <Grid
                container
                // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                style={{
                  // paddingLeft: 16,
                  // paddingTop: 8,
                  justifyContent:"center",
                  display:"flex",
                  // flexDirection:"column"
                }}
                >
                {!selectedImage && (
                  <>
                <Grid > 
                  <Typography
                     style={fontStyle.FormLabel}
                  >
                    {t("Upload photo")}
                  </Typography>
                </Grid>
                <Grid >
                  <Typography
                    style={{
                      textAlign: "left",
                      fontSize: "12px",
                      paddingTop: 7,
                      color: "red",
                    }}
                  >
                    *{t("Required")}.
                  </Typography>
                </Grid>
                </>
              )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion
          defaultExpanded
          expanded={activeSection === "family"}
          style={{
            // backgroundColor: "red",
            margin: "30px 0px",
            borderRadius: activeSection === "family" ? "10px 10px 10px 10px" : "10px 10px 10px 10px",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon style={{ color: "white", fontSize: 45 }} />
            }
            aria-controls="personal-details-content"
            id="personal-details-header"
            onClick={handleChange1("family")}
            style={{
              //   backgroundColor: "rgba(124, 0, 31, 1)",
              backgroundColor: "#EC0673",
              borderRadius: activeSection === "family" ? "10px 10px 0 0" : "10px 10px 10px 10px",
              padding: window.innerWidth > 500 ?"4px 40px" : "4px 10px" ,
            }}
          >
            <Typography
               style={fontStyle.FormHead1}
            >
              {t("Family Details")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: "50px 7%" }}>

         <Grid
  container
  spacing={2}
  style={{  }}
  justifyContent="center" // Center horizontally
>
  <Grid
    item
    xs={12}
    sm={6.8}
    md={7.18}
    style={{
      height: isLargeScreen ? 80 : 80,
      display: "flex",
      alignItems: "center",
      justifyContent: "center", // Center horizontally within this grid item
    }}
  >
    <CssTextField
      error={Boolean(fNameE)} // Pass error state to indicate validation status
      helperText={fNameE ? t("Please enter your Father Name") : null}
      size="small"
      label={t("Enter your father name")}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2
        },
      }}
      style={{
        width: isLargeScreen ? "100%" : "100%",
        alignContent: "left",
        alignSelf: "left",
        fontWeight: "bold",
        boxSizing: "border-box",
      }}
      InputProps={{
        style: {
          textAlign: "left",
        },
      }}
      value={formData.father_name}
      onChange={handleFNameChange}
    />
  </Grid>
</Grid>


<Grid
  container
  spacing={1}
  style={{  }}
  justifyContent="center" // Center horizontally
>
  <Grid
    item
    xs={12}
    sm={6.7}
    md={7.18}
    style={{
      height: isLargeScreen ? 80 : 80,
      display: "flex",
      alignItems: "center",
      justifyContent: "center", // Center horizontally within this grid item
    }}
  >
    <CssTextField
      // error={Boolean(fOccupationE)} // Pass error state to indicate validation status
      // helperText={fOccupationE ?t("Please enter your Father's occupation") : null}
      label={t("Father's occupation")}
      variant="outlined"
      select
      size="small"
      fullWidth
      
      value={formData.father_employed_in_id}
      onChange={handleFempChange}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2
        },
      }}
      InputProps={{
        style: {
          textAlign: "left",
        },
      }}
      style={{
        width: "99.5%",
        alignContent: "left",
        alignSelf: "left",
        fontWeight: "bold",
        boxSizing: "border-box",
      }}
    >
      {employeeOp.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      ))}
    </CssTextField>
  </Grid>
</Grid>



<Grid
  container
  spacing={2}
  style={{  }}
  justifyContent="center" // Center horizontally
>
  <Grid
    item
    xs={12}
    sm={6.8}
    md={7.18}
    style={{
      height: isLargeScreen ? 80 : 80,
      display: "flex",
      alignItems: "center",
      justifyContent: "center", // Center horizontally within this grid item
    }}
  >
    <CssTextField
      error={Boolean(mNameE)} // Pass error state to indicate validation status
      helperText={mNameE ? t("Please enter your Mother Name") : null}
      size="small"
      label={t("Enter your mother name")}
      style={{
        width: isLargeScreen ? "100%" : "100%",
        alignContent: "left",
        alignSelf: "left",
        fontWeight: "bold",
        boxSizing: "border-box",
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2
        },
      }}
      InputProps={{
        style: {
          textAlign: "left",
        },
      }}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      value={formData.mother_name}
      onChange={handleMNameChange}
    />
  </Grid>
</Grid>


<Grid
  container
  style={{  }}
  justifyContent="center" // Center horizontally
>
  <Grid
    item
    xs={12}
    sm={6.7}
    md={7.1}
    style={{
      height: isLargeScreen ? 80 : 80,
      display: "flex",
      alignItems: "center",
      justifyContent: "center", // Center horizontally within this grid item
    }}
  >
    <CssTextField
      // error={Boolean(mOccupationE)} // Pass error state to indicate validation status
      // helperText={mOccupationE ? t("Please enter your Mother's occupation") : null}
      label={t("Mother's Occupation")}
      variant="outlined"
      select
      size="small"
      
      fullWidth
      value={formData.mother_employed_in_id}
      onChange={handleMempChange}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2
        },
      }}
      InputProps={{
        style: {
          textAlign: "left",
        },
      }}
      style={{
        width: "99.5%",
        alignContent: "left",
        alignSelf: "left",
        fontWeight: "bold",
        boxSizing: "border-box",
      }}
    >
      {employeeOp.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      ))}
    </CssTextField>
  </Grid>
</Grid>


<Grid
  container
  spacing={2}
  style={{  }}
  justifyContent="center" // Center horizontally
>
  <Grid
    item
    xs={12}
    sm={6.8}
    md={7.18}
    style={{
      height: isLargeScreen ? 80 : 80,
      display: "flex",
      alignItems: "center",
      justifyContent: "center", // Center horizontally within this grid item
    }}
  >
    <CssTextField
      error={Boolean(brothersE)} // Pass error state to indicate validation status
      helperText={brothersE ? t("Please enter your Total Brothers") : null}
      label={t("Number of brothers")}
      variant="outlined"
      select
      size="small"
      
      fullWidth
      value={formData.total_brother}
      onChange={handleBChange}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2
        },
      }}
      InputProps={{
        style: {
          textAlign: "left",
        },
      }}
      style={{
        width: isLargeScreen ? "100%" : "100%",
        alignContent: "left",
        alignSelf: "left",
        fontWeight: "bold",
        boxSizing: "border-box",
      }}
    >
      {number.map((item) => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </CssTextField>
  </Grid>
</Grid>


<Grid
  container
  spacing={2}
  style={{  }}
  justifyContent="center" // Center horizontally
>
  <Grid
    item
    xs={12}
    sm={6.8}
    md={7.18}
    style={{
      height: isLargeScreen ? 80 : 80,
      display: "flex",
      alignItems: "center",
      justifyContent: "center", // Center horizontally within this grid item
    }}
  >
    <CssTextField
      error={Boolean(sistersE)} // Pass error state to indicate validation status
      helperText={sistersE ? t("Please enter your Total Sisters") : null}
      label={t("Number of sisters")}
      variant="outlined"
      select
      
      size="small"
      fullWidth
      value={formData.total_sister}
      onChange={handleSChange}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      InputProps={{
        style: {
          textAlign: "left",
        },
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2
        },
      }}
      style={{
        width: isLargeScreen ? "100%" : "100%",
        alignContent: "left",
        alignSelf: "left",
        fontWeight: "bold",
        boxSizing: "border-box",
      }}
    >
      {number.map((item) => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </CssTextField>
  </Grid>
</Grid>


<Grid
  container
  spacing={2}
  style={{ }}
  justifyContent="center" // Center horizontally
>
  <Grid
    item
    xs={12}
    sm={6.8}
    md={7.18}
    style={{
      height: isLargeScreen ? 80 : 80,
      display: "flex",
      alignItems: "center",
      justifyContent: "center", // Center horizontally within this grid item
    }}
  >
    <CssTextField
      error={Boolean(maxBrotherE)} // Pass error state to indicate validation status
      helperText={
       maxBrotherE
          ? t("Married Brothers should be less than total brothers") // Error message for maxBrotherE
          : null // No error message if both are false
      }
      label={t("Number of brothers married")}
      variant="outlined"
      select
      size="small"
      fullWidth
      value={formData.married_brother}
      onChange={handleMBChange}
      
      onBlur={handleMBBlur}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      InputProps={{
        style: {
          textAlign: "left",
        },
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2
        },
      }}
      style={{
        width: isLargeScreen ? "100%" : "100%",
        alignContent: "left",
        alignSelf: "left",
        fontWeight: "bold",
        boxSizing: "border-box",
      }}
    >
      {number.map((item) => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </CssTextField>
  </Grid>
</Grid>


<Grid
  container
  spacing={2}
  style={{  }}
  justifyContent="center" // Center horizontally
>
  <Grid
    item
    xs={12}
    sm={6.8}
    md={7.18}
    style={{
      height: isLargeScreen ? 80 : 80,
      display: "flex",
      alignItems: "center",
      justifyContent: "center", // Center horizontally within this grid item
    }}
  >
    <CssTextField
      error={Boolean(maxSisterE)} // Pass error state to indicate validation status
      helperText={
        maxSisterE
          ? t("Married Sisters should be less than total sisters") 
          : null
      }
      label={t("Number of sisters married")}
      variant="outlined"
      select
      size="small"
      fullWidth
      
      value={formData.married_sister}
      onChange={handleMSChange}
      onBlur={handleMSBlur}
      InputLabelProps={{
        style: {
          color: "lightgrey",
        },
      }}
      FormHelperTextProps={{ 
        style: {
          color: "red", 
          fontSize: "12px", 
          textAlign: "left", 
          marginTop: -2
        },
      }}
      InputProps={{
        style: {
          textAlign: "left",
        },
      }}
      style={{
        width: isLargeScreen ? "100%" : "100%",
        alignContent: "left",
        alignSelf: "left",
        fontWeight: "bold",
        boxSizing: "border-box",
      }}
    >
      {number.map((item) => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </CssTextField>
  </Grid>
</Grid>

          </AccordionDetails>
        </Accordion>

        <Accordion
          defaultExpanded
          expanded={activeSection === "contact"}
          style={{
            // backgroundColor: "red",
            margin: "30px 0px",
            borderRadius: activeSection === "contact" ? "10px 10px 10px 10px" : "10px 10px 10px 10px",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon style={{ color: "white", fontSize: 45 }} />
            }
            aria-controls="personal-details-content"
            id="personal-details-header"
            onClick={handleChange1("contact")}
            style={{
              //   backgroundColor: "rgba(124, 0, 31, 1)",
              backgroundColor: "#EC0673",
              borderRadius: activeSection === "contact" ? "10px 10px 0 0" : "10px 10px 10px 10px",
              padding: window.innerWidth > 500 ?"4px 40px" : "4px 10px" ,
            }}
          >
            <Typography
               style={fontStyle.FormHead1}
            >
              {t("Contact Details")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: "50px 7%" }}>
            <Grid
              container
              spacing={2}
              style={{  }}
              justifyContent="center" // Center horizontally
            >
              
              <Grid
                item
                xs={12}
                sm={6.8}
                md={7.18}
                style={{
                  height: isLargeScreen ? 80 : 80,

                  // marginLeft: "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center", // Center horizontally within this grid item
                }}
              >
                <CssTextField
                  error={Boolean(contactNoE)} // Pass error state to indicate validation status
                  helperText={
                    contactNoE ? t("Please enter your Contact Number") : null
                  }
                  label={t("Enter your contact number")}
                  size="small"
                  type="tel"
                  inputProps={{
                    pattern: "[0-9]*",
                    maxLength: 10,
                    minLength: 10,
                  }}
                  style={{
                    width: isLargeScreen ? "100%" : "100%",
                    alignContent: "left",
                    alignSelf: "left",

                    fontWeight: "bold",
                    boxSizing: "border-box",
                    // backgroundColor: "white",
                  }}
                  InputLabelProps={{
                    style: {
                      color: "lightgrey",
                    },
                  }}
                  FormHelperTextProps={{ 
                    style: {
                      color: "red", 
                      fontSize: "12px", 
                      textAlign: "left", 
                      marginTop: -2
                    },
                  }}
                  value={formData.contact_number}
                  onChange={handlecontactNoChange}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{
                // paddingTop: 15,
                
              }}
              justifyContent="center" // Center horizontally
            >
              

              <Grid
                item
                xs={12}
                sm={6.8}
                md={7.18}
                style={{
               
                  height: isLargeScreen ? 80 : 80,
                  // marginLeft: "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CssTextField
                  error={Boolean(emailE)} // Pass error state to indicate validation status
                  helperText={emailE ? t("Please enter valid Email ID") : null}
                  type="email"
                  size="small"
                  label={t("Enter your email")}
                  style={{
                    width: isLargeScreen ? "100%" : "100%",
                    alignContent: "left",
                    alignSelf: "left",

                    fontWeight: "bold",
                    boxSizing: "border-box",
                  }}
                  InputProps={{
                    style: {
                      color: "",
                      textAlign: "left",
                    },
                  }}
                  FormHelperTextProps={{ 
                    style: {
                      color: "red", 
                      fontSize: "12px", 
                      textAlign: "left", 
                      marginTop: -2
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "lightgrey",
                    },
                  }}
                  value={formData.email_id}
                  onChange={handleEmailChange}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{  }}
              justifyContent="center" // Center horizontally
            >
              
              <Grid
                item
                xs={12}
                sm={6.8}
                md={7.18}
                style={{
                  height: isLargeScreen ? 80 : 80,
                  // marginLeft: "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CssTextField
                  error={Boolean(addressE)} // Pass error state to indicate validation status
                  helperText={addressE ? t("Please enter your Address") : null}
                  size="small"
                  label={t("Address Line 1")}
                  inputProps={{
                    style: {
                      color: "black",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "lightgrey",
                    },
                  }}
                  FormHelperTextProps={{ 
                    style: {
                      color: "red", 
                      fontSize: "12px", 
                      textAlign: "left", 
                      marginTop: -2
                    },
                  }}
                  name="addressLine1"
                  value={formData.addressLine1}
                  onChange={handleaddress1Change}
                  style={{
                    width: isLargeScreen ? "100%" : "100%",
                    alignContent: "left",
                    alignSelf: "left",

                    fontWeight: "bold",
                    boxSizing: "border-box",
                    // backgroundColor: "white",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6.8}
                md={7.18}
                style={{
                  // height: isLargeScreen ? 80 : 80,
                  // marginLeft: "auto",
                  display: "flex",

                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "-15px",
                }}
              >
                <CssTextField
                  //   error={Boolean(addressE)} // Pass error state to indicate validation status
                  //   helperText={addressE ? "Please enter your Address" : null}
                  size="small"
                  label={t("Address Line 2")}
                  inputProps={{
                    style: {
                      color: "black",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "lightgrey",
                    },
                  }}
                  name="addressLine2"
                  value={formData.addressLine2}
                  onChange={handleaddress2Change}
                  style={{
                    width: isLargeScreen ? "100%" : "100%",
                    alignContent: "left",
                    alignSelf: "left",

                    fontWeight: "bold",
                    boxSizing: "border-box",
                    // backgroundColor: "white",
                  }}
                />
              </Grid>
            </Grid>

            {/* {error && <h5 style={{ color: "red" }}>{error}</h5>} */}
          </AccordionDetails>
        </Accordion>

        <Grid style={{ padding: "30px 0px" }}>
        {renderThemeTitle()}
        </Grid>

        <Grid
          container
          spacing={3}
          sx={{
            display: { xs: "none", sm: "flex", md: "flex", lg: "flex" },
            marginBottom: 0,
          }}
        >
          {tempOp.map((image) => (
            <Grid key={image.id} item xs={4}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "2rem",
                  alignItems: "center",
                  alignContent: "end",
                }}
              >
                {image.is_premium == 1 && (
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",

                      zIndex: 1,
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          height: "1.5rem",
                          backgroundColor: "#EC0673",
                          padding: "2%",
                          display: "flex",

                          alignItems: "center",
                          borderRadius: "10px 0 0 10px", // to match the rounded border of the price div
                          boxShadow:
                            "2.8px 2.8px 2.7px rgba(0, 0, 0, 0.112)," +
                            " 7px 7px 6.9px rgba(0, 0, 0, 0.127)," +
                            "14.4px 14.4px 14.2px rgba(0, 0, 0, 0.152)," +
                            "29.6px 29.6px 29.2px rgba(0, 0, 0, 0.186)," +
                            "81px 81px 80px rgba(0, 0, 0, 0.23)",
                        }}
                      >
                        <Typography
                          style={{
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            width: "4.3rem",
                            margin: "10%",
                            fontFamily: "Avenir"
                          }}
                        >
                          ₹{image.price}
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={ImagePath.Picon}
                        style={{
                          width: "3rem",
                          height: "3rem",
                          borderRadius: "10px 10px 10px 10px",
                          position: "absolute",
                          display: "flex",
                          justifySelf: "end",
                        }}
                      ></img>
                    </div>
                  </div>
                )}
                 {image.is_premium == 0 && (
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",

                      zIndex: 1,
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "end" }}>
                      <div
                        style={{
                          height: "1.5rem",
                          backgroundColor: "#EC0673",
                          padding: "2%",
                          display: "flex",

                          alignItems: "center",
                          borderRadius: "10px 10px 10px 10px", // to match the rounded border of the price div
                          boxShadow:
                            "2.8px 2.8px 2.7px rgba(0, 0, 0, 0.112)," +
                            " 7px 7px 6.9px rgba(0, 0, 0, 0.127)," +
                            "14.4px 14.4px 14.2px rgba(0, 0, 0, 0.152)," +
                            "29.6px 29.6px 29.2px rgba(0, 0, 0, 0.186)," +
                            "81px 81px 80px rgba(0, 0, 0, 0.23)",
                        }}
                      >
                        <Typography
                          style={{
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            width: "3.5rem",
                            margin: "15%",
                            fontFamily: "Avenir"
                          }}
                        >
                          FREE
                        </Typography>
                      </div>
                    </div>
                   
                  </div>
                )}
              </div>
              <img
                error={Boolean(templateE)} // Pass error state to indicate validation status
                helperText={
                  templateE ? t("Please Select template for your Biodata") : null
                }
                src={`https://includetechnology.com/MarriageBiodata/public/img/templates/${image.sample_path}`}
                alt={`${image.name}`}
                style={{
                  width: "90%",
                  height: "90%",
                  cursor: "pointer",
                  //   border: "1px solid brown",
                  boxShadow:
                    "4.4px 4.4px 2.2px rgba(0, 0, 0, 0.031), " +
                    "9.6px 9.6px 5.3px rgba(0, 0, 0, 0.042), " +
                    "16.3px 16.3px 10px rgba(0, 0, 0, 0.05), " +
                    "26.4px 26.4px 17.9px rgba(0, 0, 0, 0.057), " +
                    "45.1px 45.1px 33.4px rgba(0, 0, 0, 0.066), " +
                    "100px 100px 80px rgba(0, 0, 0, 0.09)",
                  borderRadius: 10,
                  border: template == image.id ? "4px solid gold" : "none",
                }}
                // onChange={handleTemplateChange}
                onClick={() =>
                  handleTemplateChange(image.id, image.template_name)
                }
              />
            </Grid>
          ))}
          {templateE && (
            <h4 style={{ color: "red", paddingLeft: "4%", paddingTop: -10 }}>
              {t("Please Select template for your Biodata")}
            </h4>
          )}
        </Grid>

        <Grid
          container
          spacing={3}
          sx={{
            display: { xs: "flex", sm: "none" },
            marginBottom: fileE ? 0 : 6,
          }}
        >
          {tempOp.map((image) => (
            <Grid key={image.id} item xs={6}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "1rem",
                  alignItems: "center",
                  alignContent: "end",
                }}
              >
                {image.is_premium == 1 && (
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",

                      zIndex: 1,
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          height: "1.4rem",
                          backgroundColor: "#EC0673",
                          padding: 0,

                          display: "flex",
                          paddingRight: 0,
                          alignItems: "center",
                          borderRadius: "10px 0 0 10px", // to match the rounded border of the price div
                          boxShadow:
                            "2.8px 2.8px 2.7px rgba(0, 0, 0, 0.112)," +
                            " 7px 7px 6.9px rgba(0, 0, 0, 0.127)," +
                            "14.4px 14.4px 14.2px rgba(0, 0, 0, 0.152)," +
                            "29.6px 29.6px 29.2px rgba(0, 0, 0, 0.186)," +
                            "81px 81px 80px rgba(0, 0, 0, 0.23)",
                        }}
                      >
                        <Typography
                          style={{
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            width: "3.5rem",
                            marginLeft: "7px",
                            fontFamily: "Avenir"
                          }}
                        >
                          ₹{image.price}
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={ImagePath.Picon}
                        style={{
                          width: "2.5rem",
                          height: "2.5rem",
                          borderRadius: "10px 10px 10px 10px",
                          position: "absolute",
                          display: "flex",
                          justifySelf: "end",
                        }}
                      ></img>
                    </div>
                  </div>
                )}
                {image.is_premium == 0 && (
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",

                      zIndex: 1,
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "CENTER" }}>
                      <div
                        style={{
                          height: "1.5rem",
                          backgroundColor: "#EC0673",
                          padding: "2%",
                          display: "flex",

                          alignItems: "center",
                          borderRadius: "10px 10px 10px 10px", // to match the rounded border of the price div
                          boxShadow:
                            "2.8px 2.8px 2.7px rgba(0, 0, 0, 0.112)," +
                            " 7px 7px 6.9px rgba(0, 0, 0, 0.127)," +
                            "14.4px 14.4px 14.2px rgba(0, 0, 0, 0.152)," +
                            "29.6px 29.6px 29.2px rgba(0, 0, 0, 0.186)," +
                            "81px 81px 80px rgba(0, 0, 0, 0.23)",
                        }}
                      >
                        <Typography
                          style={{
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            width: "3.5rem",
                            margin: "15%",
                            fontFamily: "Avenir"
                          }}
                        >
                          FREE
                        </Typography>
                      </div>
                    </div>
                   
                  </div>
                )}
              </div>
              <img
                src={`https://includetechnology.com/MarriageBiodata/public/img/templates/${image.sample_path}`}
                alt={`${image.name}`}
                style={{
                  width: "95%",
                  height: "95%",
                  cursor: "pointer",
                  //   border: "1px solid brown",
                  boxShadow:
                    "4.4px 4.4px 2.2px rgba(0, 0, 0, 0.031), " +
                    "9.6px 9.6px 5.3px rgba(0, 0, 0, 0.042), " +
                    "16.3px 16.3px 10px rgba(0, 0, 0, 0.05), " +
                    "26.4px 26.4px 17.9px rgba(0, 0, 0, 0.057), " +
                    "45.1px 45.1px 33.4px rgba(0, 0, 0, 0.066), " +
                    "100px 100px 80px rgba(0, 0, 0, 0.09)",
                  borderRadius: 5,
                  border: template == image.id ? "4px solid gold" : "none",
                }}
                // onChange={handleTemplateChange}
                onClick={() =>
                  handleTemplateChange(image.id, image.template_name)
                }
              />
            </Grid>
          ))}
          {templateE && (
            <h5 style={{ color: "red", paddingLeft: "7%", paddingTop: -10 }}>
              {t("Please Select template for your Biodata")}
            </h5>
          )}
        </Grid>
          {fileE && (<h5 style={{ color: "red", paddingLeft: "3%", paddingTop: -10 }}>{t(fileE)}</h5>)}

        <Grid
          container
          style={{
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            alignSelf: "center",
            display: "flex",
          }}
        >
          <Button
            type="submit"
            onClick={() => validateForm()}
            sx={{
              display: "flex",
              height: 60,
              width: 250,
              // borderRadius: 10,
              color: "white",
              background:
                "#EC0673",
                "&:hover": {
                  color: "black",
                  backgroundColor: "rgb(255, 210, 235)"
                }
            }}
          >
            <Typography style={{  fontSize: 20, fontFamily: "Avenir", textTransform: "none" }}>
              Preview BioData
            </Typography>
          </Button>
        </Grid>
      </form>
    </Grid>
    </Grid>
  );
};

export default Form;

